import React, { Fragment, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GlobalSet } from '../../global/GlobalProvider';
import PageTitle from "../../../layouts/PageTitle";
import axios from "axios";
import swal from "sweetalert";
import { useTranslation } from 'react-i18next';

const Checkout = () => {
    const { t } = useTranslation();
    const [withdraw, setWithdraw] = useState([]);
    const [ActiveMehod, setActiveMehod] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingdd, setLoadingd] = useState(false); // Loading state
    const [selectedTypeOfPlot, setTypeOfPlot] = useState([]); // Array to store selected IDs
    const { BaseUrl } = GlobalSet();
    const agentActiveArray = ActiveMehod.split(',').map(Number); // Active methods array

    // Initialize selectedTypeOfPlot when ActiveMehod is received
    useEffect(() => {
        if (ActiveMehod) {
            const activeMethodsArray = ActiveMehod.split(',').map(Number);
            setTypeOfPlot(activeMethodsArray); // Set the selected methods
        }
    }, [ActiveMehod]);

    const handleCheckboxChange = (id) => {
        setTypeOfPlot((prevSelected) => {
            if (prevSelected.includes(id)) {
                // Remove the item if already selected
                return prevSelected.filter(item => item !== id);
            } else {
                // Add the item if not selected
                return [...prevSelected, id];
            }
        });
    };




  const fetchData = () => {
    const data = {
        key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
    };
    axios.post('/apps-withdraw-method', data)
        .then(response => { 
            setWithdraw(response.data.data);
            setActiveMehod(response.data.active_method);
            setLoadingd(true);

            if (response.data.status === 'success') {
              swal("Success", response.data.message, "success");
              setDepositInput({
                ...depositInput,
                amount: '',
                password: '',
                error_list: []
              });
            } else if (response.data.status === 'fail') {
              swal("Warning", response.data.message, "warning");
            } else {
              setDepositInput({ ...depositInput, error_list: response.data.validation_error });
            }

        })

        .catch(error => {
            console.log(error);
        });
  };
  useEffect(() => {
    fetchData();
  }, []);








    const [depositInput, setDepositInput] = useState({
        gateway_id: '',
        amount: '',
        transaction_number: '',
        password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setDepositInput({ ...depositInput, [e.target.name]: e.target.value });
    };

    const depositSubmit = (e) => {
        e.preventDefault();
        const auth_token = localStorage.getItem('auth_token');
        const selectedCurrency = localStorage.getItem('selectedCurrency');
        const data = {
            gateway_id: selectedTypeOfPlot, // Use the selected payment method(s)
            currency: selectedCurrency,
            amount: depositInput.amount,
            transaction_number: depositInput.transaction_number,
            password: depositInput.password,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        };

        if (selectedTypeOfPlot.length === 0) {
            swal("Error", "Please select at least one payment method", "error");
            return;
        }

        if (auth_token !== null && !loading) {
            setLoading(true);
            axios.post(`/apps-ag-method-sub`, data).then(res => {
                setLoading(false);
                if (res.data.status === 'success') {
                    swal("Success", res.data.message, "success");
                    fetchData();
                } else if (res.data.status === 'fail') {
                    swal("Warning", res.data.message, "warning");
                } else {
                    swal("Warning", res.data.message, "warning");
                }
                setLoading(false);
            });
        }
    };

    let projectTypesdd = null;
    if (loadingdd) {
        projectTypesdd = (
            <>
                {withdraw.length > 0 && (
                    <h4 className="mb-3">Add Withdraw Method</h4>
                )}
                {withdraw.map((getwayitem) => (
                    <div key={getwayitem.id} className="col-lg-12">
                        <div>
                            <div style={{ background: "#3e3e3e" }} className="card-header border-0 pt-2 pb-2">
                                <h5 className="text-white">{t(getwayitem.payment_type)}</h5>
                                <img src={BaseUrl + (getwayitem.photo)} alt="" className="me-3 rounded" width={75} height={30} />
                            </div>

                            <div className="row pt-0 pb-0 p-3">
                                {getwayitem.allmatch.map((element) => (
                                    <div className="col-xl-2 col-xxl-3 col-lg-3 col-md-4 col-sm-6 col-6 int-col p-1" key={element.id}>
                                        <label data-kt-button="true">
                                            <div key={element.id}>
                                                <div className="card mb-0">
                                                    <div className="img-bx text-center">
                                                        <img src={BaseUrl + (element.photo)} alt="" className="me-3 m-3 card-list-img" width="35%" />
                                                        <h5 className={`nav-text text-center p-1 rounded-bottom ${agentActiveArray.includes(element.id) ? 'bg-warning' : 'bg-light'}`}>
                                                            {element.Payment_name + " "}
                                                            <input
                                                                className="form-check-input border border-gray-500"
                                                                type="checkbox"
                                                                name="type_of_plot"
                                                                value={element.id}
                                                                checked={selectedTypeOfPlot.includes(element.id)}  // Use checked instead of defaultChecked
                                                                onChange={() => handleCheckboxChange(element.id)}  // Toggle check/uncheck
                                                            />
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </>
        );
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12 order-md-1">
                                    <form onSubmit={depositSubmit} className="needs-validation" noValidate="">
                                        <div className="row">
                                            <div style={{ margin: "0px auto" }}>
                                                {projectTypesdd}
                                            </div>
                                        </div>

                                        <hr className="mb-4" />
                                        <button
                                            className="btn btn-primary btn-lg btn-block"
                                            type="submit"
                                        >
                                            {loading ? t('comon_Submitting') : t('comon_Submit')}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Checkout;
